<template>
  <div>
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden model_height model_bg"
      :id="'sidebar-modal'"
    >
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-900 opacity-75" />
      </div>
      <div class="container mx-auto px-4 h-full">
        <div class="flex content-center items-center justify-center h-full">
          <div class="w-full lg:w-4/12 modal_width_sidebar px-4">
            <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg_model border-0"
            >
              <div class="rounded-t mb-0 px-6 py-6">
                <div class="text-center mb-3">
                  <h3 class="text-md font-bold theme_text">
                    Edit {{ sideBarForProp }}
                  </h3>
                </div>
              </div>
              <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form>
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-xs font-bold mb-2 theme_text"
                      htmlFor="grid-password"
                    >
                      Rename
                    </label>
                    <input
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-50 bg_input rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      v-model="savedName"
                    />
                  </div>

                  <div class="text-center mt-6 flex btns_modal_p">
                    <button
                      type="button"
                      class="px-4 bg-gray-500 text-sm bg_cancel bg_modal_btns_sidebar rounded hover:bg-gray-700 text-sm font-bold button uppercase px-6 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 py-1"
                      @click="toggleModal()"
                    >
                      <i class="fas fa-times"></i> Cancel
                    </button>

                    <button
                      class="bg-green-600 bg_create bg_modal_btns_sidebar active:bg-green-800 text-sm font-bold button uppercase px-6 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 py-1"
                      type="button"
                      @click="onRename()"
                    >
                      <i class="fas fa-edit"></i> Rename
                    </button>
                    <button
                      v-if="this.activeFor == 'dashboard'"
                      type="button"
                      class="px-4 bg-gray-500 text-sm bg_cancel bg_modal_btns_sidebar bg_clear rounded hover:bg-gray-700 text-sm font-bold button uppercase px-6 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 py-1"
                      @click="onClear()"
                    >
                      <i class="fas fa-trash"></i> Clear
                    </button>
                    <button
                      type="button"
                      class="px-4 bg-gray-500 text-sm bg_cancel bg_modal_btns_sidebar rounded hover:bg-gray-700 text-sm font-bold button uppercase px-6 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 py-1"
                      @click="onDelete()"
                    >
                      <i class="fas fa-trash"></i> Delete
                    </button>
                  </div>
                </form>
                <div class="text-red-500">
                  {{ errcode }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="asidebarDiv absolute left_z_index"
      :class="darkDark !== 'white' ? 'sideBarBG' : 'left_bg'"
    >
      <!-- <div>{{ darkDark}}</div> -->
      <nav
        v-if="$store.state.uis.leftSideBarOpen"
        class="asidebar close-sidebar-16rem z_index_20 md:left-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between z-50 w-100"
        id="mainNavBar"
        v-bind:style="{ left: toggleSidebarProp + 'rem' }"
      >
        <div
          class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto mx_height_side"
        >
          <button
            class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')"
          >
            <i class="fas fa-bars"></i>
          </button>

          <div
            class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded mt-0"
            v-bind:class="collapseShow"
          >
            <!-- <div class="px-1 py-0 flex bg-emerald-500 mneminics_mt mb-2">
              <h6 class="px-2 py-0 drop_btn font-thin text-center text-white"
                style="width: 100%; display: flex; justify-content: center">
                {{ sideBarForProp }}
              </h6>
            </div> -->

            <div class="mneminics_mt">
              <h6
                class="sideBarHdng drop_btn uppercase"
                style="width: 100%; display: flex"
              >
                {{ sideBarForProp }}
                <span
                  v-if="sideBarForProp == 'Mnemonics'"
                  @click="reloadMenmonicsHandler"
                  class="pl-4 cursor-pointer"
                  ><i class="fas fa-sync fa-xs"></i
                ></span>
              </h6>
            </div>
            <div
              class="asideSearch flex items-center"
              :class="darkDark !== 'white' ? ' bgDark' : 'bgLight'"
            >
              <!-- search icon -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                :class="darkDark == 'white' && 'asideSearchIcon'"
              >
                <path
                  d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                  fill="#37B6FE"
                ></path>
              </svg>
              <input
                type="text"
                placeholder="Search"
                v-model="search"
                class="asideSearchIp border-0 search text-xs shadow outline-none focus:outline-none pl-3 uppercase"
              />
            </div>

            <div v-if="showInsideData == false" class="left_lists_">
              <ul
                v-if="sideBarForProp == 'Mnemonics'"
                class="left_mnemo h-full"
              >
                <li>
                  <template v-for="(tag, index) in tags">
                    <div
                      v-if="
                        tag != 'Email Sent' &&
                        tag != 'SMS Sent' &&
                        tag &&
                        tag.toLowerCase().indexOf(search.toLowerCase()) > -1
                      "
                      class="selectedBtn py-2 relative"
                      :key="index"
                      @click="toolTipHandler(index)"
                      @mouseleave="activeToolTip = -1"
                      :title="description && description[index]"
                    >
                      <div
                        class="sideBarListBtn flex items-center cursor-pointer dropdown_hover selectedBtn_tooltip font-bold text-left break-all tooltip__tip top _popper_styles"
                        :class="
                          darkDark !== 'white' ? 'drop_btn' : 'drop_btn_light'
                        "
                        draggable="true"
                        @dragstart="
                          startDrag($event, {
                            name: 'numeric',
                            width: 180,
                            height: 44,
                            title: tag,
                            index: index,
                            borderDisplay: false,
                            unitDisplay: true,
                          })
                        "
                      >
                        <span class="listtxt">:&nbsp;{{ tag }}</span>
                      </div>
                      <div
                        class="absolute customtooltip z-10 inline-block al px-3 py-0 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700"
                        :class="activeToolTip == index ? 'visible' : 'hidden'"
                      >
                        Please Hold & Drag
                      </div>
                    </div>
                  </template>
                </li>
              </ul>

              <ul
                v-if="sideBarForProp == 'DashBoard'"
                class="left_mnemo h-full"
              >
                <p
                  class="alert_message"
                  v-if="dashboards && !dashboards.length"
                >
                  No Dashboards found
                </p>
                <li
                  class="text-xs"
                  v-for="(dashboard, index) in dashboards"
                  :key="index"
                >
                  <div
                    class="flex items-center justify-center"
                    v-if="
                      dashboard.name &&
                      dashboard.name
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) > -1
                    "
                  >
                    <button
                      @contextmenu="
                        toggleModal($event, {
                          activeFor: 'dashboard',
                          savedDbId: dashboard.id,
                          savedDbName: dashboard.name,
                        })
                      "
                      :class="[
                        '',
                        'sideBarListBtn',
                        'flex',
                        'items-center',
                        'font-bold',
                        'text-left',
                        'w-11/12',
                        'rounded',
                        this.activeIndexDB == index
                          ? 'bg-green-700'
                          : 'dropdown_hover',
                        darkDark !== 'white' ? 'drop_btn' : 'drop_btn_light',
                      ]"
                      draggable="true"
                      @dragstart="
                        startDragSaved($event, {
                          ...dashboard.dashboard,
                          name: 'dashboard',
                          savedDbId: dashboard.id,
                          savedName: dashboard.name,
                        })
                      "
                      @dragend="saveDragedIndex($event, index)"
                      @click="dasboardClicked(dashboard.id)"
                    >
                      <div class="listicon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="4"
                          viewBox="0 0 12 4"
                          fill="none"
                        >
                          <path
                            d="M11.3332 0H0.666504V1.33333H11.3332V0ZM0.666504 4H11.3332V2.66667H0.666504V4Z"
                            fill="#B4B8BE"
                          ></path>
                        </svg>
                      </div>
                      <span class="listtxt">{{ dashboard.name }}</span>
                    </button>
                  </div>
                </li>
              </ul>

              <ul v-if="sideBarForProp == 'Screens'" class="left_mnemo h-full">
                <p class="alert_message" v-if="screens && !screens.length">
                  No Screen Found
                </p>
                <li
                  class="text-xs"
                  v-for="(screen, index) in screens"
                  :key="index"
                >
                  <div
                    class="flex items-center justify-center"
                    v-if="
                      screen.name &&
                      screen.name.toLowerCase().indexOf(search.toLowerCase()) >
                        -1
                    "
                  >
                    <button
                      @contextmenu="
                        toggleModal($event, {
                          savedId: screen.id,
                          savedName: screen.name,
                        })
                      "
                      :class="[
                        '',
                        'sideBarListBtn',
                        'flex',
                        'items-center',
                        'font-bold',
                        'text-left',
                        'w-11/12',
                        'rounded',
                        this.activeIndexSrn == index
                          ? 'bg-green-700'
                          : 'dropdown_hover',
                        darkDark !== 'white' ? 'drop_btn' : 'drop_btn_light',
                      ]"
                      draggable="true"
                      @dragstart="
                        startDragSaved($event, {
                          ...screen.screen,
                          name: 'display',
                          savedId: screen.id,
                          savedName: screen.name,
                        })
                      "
                      @dragend="saveSrnDragedIndex($event, index)"
                      @click="screenClicked(screen.id)"
                    >
                      <div class="listicon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="4"
                          viewBox="0 0 12 4"
                          fill="none"
                        >
                          <path
                            d="M11.3332 0H0.666504V1.33333H11.3332V0ZM0.666504 4H11.3332V2.66667H0.666504V4Z"
                            fill="#B4B8BE"
                          ></path>
                        </svg>
                      </div>
                      <span class="listtxt">{{ screen.name }}</span>
                    </button>
                  </div>
                </li>
              </ul>

              <ul
                v-if="sideBarForProp == 'Background'"
                class="left_mnemo h-full"
              >
                <p
                  class="alert_message"
                  v-if="backgrounds && !backgrounds.length"
                >
                  No Background Found
                </p>
                <li
                  class="text-xs"
                  v-for="(background, index) in backgrounds"
                  :key="index"
                >
                  <div
                    class="flex items-center justify-center"
                    v-if="
                      background.name &&
                      background.name
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) > -1
                    "
                  >
                    <button
                      @contextmenu="
                        toggleModal($event, {
                          savedId: background.id,
                          savedName: background.name,
                        })
                      "
                      :class="[
                        'sideBarListBtn',
                        'flex',
                        'items-center',
                        'font-bold',
                        'text-left',
                        'w-11/12',
                        'rounded',
                        this.activeIndexBg == index
                          ? 'bg-green-700'
                          : 'dropdown_hover',
                        darkDark !== 'white' ? 'drop_btn' : 'drop_btn_light',
                      ]"
                      draggable="true"
                      @dragstart="
                        startDragSaved($event, {
                          ...background.background,
                          name: 'Background',
                          savedId: background.id,
                          savedName: background.name,
                        })
                      "
                      @dragend="saveBgDragedIndex($event, index)"
                    >
                      <div class="listicon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="4"
                          viewBox="0 0 12 4"
                          fill="none"
                        >
                          <path
                            d="M11.3332 0H0.666504V1.33333H11.3332V0ZM0.666504 4H11.3332V2.66667H0.666504V4Z"
                            fill="#B4B8BE"
                          ></path>
                        </svg>
                      </div>
                      <span class="listtxt">{{ background.name }}</span>
                    </button>
                  </div>
                </li>
              </ul>
            </div>

            <div>
              <div
                class="expand-right-sidebar-feature-one left_side_bottom_expand"
              >
                <div class="px-4 py-1">
                  <a class="general_checks">
                    <p class="label_heading" @click="toggleDragHandler()">
                      Drag
                    </p>

                    <!-- <input class="general_check" type="checkbox" id="dragPosition" :checked="isDraggableWidgets" /> -->
                    <label class="switch" for="dragPosition">
                      <input
                        id="dragPosition"
                        type="checkbox"
                        :checked="isDraggableWidgets"
                        @change="togglee"
                        v-model="checkedOrNot"
                      />
                      <span class="slider round"></span>
                    </label>
                  </a>
                  <div class="general_checks">
                    <p class="label_heading">Resize</p>
                    <!-- <input class="general_check" type="checkbox" id="resizePosition" @click="toggleResizeHandler()"
                      checked /> -->
                    <label class="switch" for="resizePosition">
                      <input
                        id="resizePosition"
                        type="checkbox"
                        checked
                        @click="toggleResizeHandler()"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <!-- <div class="general_checks">
                    <p class="label_heading">Data Gap Analysis</p>
                    <label class="switch" for="dataGapAnalysis">
                      <input
                        type="checkbox"
                        ref="dataGapref"
                        id="dataGapAnalysis"
                        @click="toggleDataGapAnalysis($event)"
                      />
                      <span class="slider round"></span>
                    </label>
                    <DataGapsAndNulls
                      @close="close_Data_Gap_Popup"
                      v-if="show_DataGaps_Popup"
                    />
                  </div> -->
                </div>
              </div>
            </div>
          </div>

          <div v-if="showInsideData == true">
            <div class="flex" v-on:click="showInsideData = false">
              <p class="ml-2 text-sm">Active Well 1</p>
            </div>
            <ul class>
              <li class>
                <div class="my-4">
                  <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
                    <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                      <a
                        class="text-xs font-bold uppercase tab_ px-5 py-1 shadow-lg rounded block leading-normal"
                        v-on:click="toggleServices(1)"
                        v-bind:class="{
                          '-600 bg-white': openService !== 1,
                          ' bg-green-500': openService === 1,
                        }"
                      >
                        <i class="fas fa-space-shuttle text-base mr-1"></i> Tags
                      </a>
                    </li>
                    <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                      <a
                        class="text-xs font-bold uppercase tab_ px-5 py-1 shadow-lg rounded block leading-normal"
                        v-on:click="toggleServices(2)"
                        v-bind:class="{
                          '-600 bg-white': openService !== 2,
                          ' bg-green-500': openService === 2,
                        }"
                      >
                        <i class="fas fa-cog text-base mr-1"></i> Mnemonics
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="w-full lg:w-4/12 px-4">
                  <div
                    v-bind:class="{
                      hidden: openService !== 1,
                      block: openService === 1,
                    }"
                  >
                    <p class="">Tags Data</p>
                  </div>
                  <div
                    v-bind:class="{
                      hidden: openService !== 2,
                      block: openService === 2,
                    }"
                  >
                    <p class="">mnemonics Data</p>
                  </div>
                </div>
              </li>
              <li class>
                <div>
                  <p class="menu_label_option mt-3">mnemonics data 1</p>
                </div>
              </li>
              <li class>
                <div>
                  <p class="menu_label_option mt-3">mnemonics data 1</p>
                </div>
              </li>
              <li class>
                <div>
                  <p class="menu_label_option mt-3">mnemonics data 1</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import move from "../../assets/img/move.png";
import api from "../../api/services";
import DataGapsAndNulls from "../DataGapAnalysis/DataGapsAndNulls.vue";
import helperServices from "../../helper-services";
import { db } from "../../db";
let flag = true;
export default {
  data() {
    return {
      checkedOrNot: this.isDraggableWidgets,
      hoverLeftSidebarX: "",
      hoverleftSiderbarY: "",
      errcode: "",
      collapseShow: "hidden",
      toggleSidebar: "",
      toggleSideDbbar: this.toggleSidebarProp,
      showInsideData: false,
      openTab: 1,
      openService: 1,
      isOpen: true,
      search: "",
      value: 10,
      max: 0,
      orientation: "vertical",
      direction: "rtl",
      move,
      displayTags: [],
      mneTags: [],
      units: [],
      logId: null,
      wellboreState: null,

      isDraggable: false,
      dashboards: [],
      screens: [],
      backgrounds: [],
      savedData: [],
      savedId: "",
      savedName: "",
      savedDbId: "",
      savedDbName: "",
      activeFor: "",
      activeIndexDB: -1,
      activeIndexSrn: -1,
      activeIndexBg: -1,
      isHovering: false,
      hoverIndex: -1,
      description: [],
      tags: [],
      show_DataGaps_Popup: false,
      activeToolTip: -1,
    };
  },
  props: {
    toggleSidebarProp: String,
    sideBarForProp: String,
    savedList: [Array, Object],
  },
  computed: {
    isDraggableWidgets() {
      let display = this.$store.state.disp.selectedDisplay;
      let displayobj = {};
      for (let i = 0; i < this.$store.state.disp.rects.length; i++) {
        if (display == this.$store.state.disp.rects[i].displayId)
          displayobj = this.$store.state.disp.rects[i];
      }
      if (displayobj && displayobj.isDraggableWidget) {
        this.isDraggable = true;
        return displayobj.isDraggableWidget;
      } else {
        this.isDraggable = false;
        return false;
      }
    },
    // sliderMin: {
    //   get: function() {
    //     var val = parseInt(this.minAngle);
    //     return val;
    //   },
    //   set: function(val) {
    //     val = parseInt(val);
    //     if (val > this.maxAngle) {
    //       this.maxAngle = val;
    //     }
    //     this.minAngle = val;
    //   }
    // },
    // sliderMax: {
    //   get: function() {
    //     var val = parseInt(this.maxAngle);
    //     return val;
    //   },
    //   set: function(val) {
    //     val = parseInt(val);
    //     if (val < this.minAngle) {
    //       this.minAngle = val;
    //     }
    //     this.maxAngle = val;
    //   }
    // }
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  async mounted() {
    let response;
    if (localStorage.getItem("670023242:az")) {
      response = await api.DashBoardServices.getSavedContents();
      this.savedData = response;
      let activeDisplay = this.$store.state.data.selectedDisplay;

      this.activeDisplay = activeDisplay;

      if (activeDisplay != null) {
        let activeBore = this.$store.state.data.displays.activeDisplay;

        if (activeBore != null) {
          let activeBoreId = activeBore.wellboreId;
          let activeLog = activeBore.logId;
          let activeLogType = activeBore.logType;
        }
        //
        //
      }
      for (let data of this.savedData.data) {
        if (data.dbtype == "dashboard") {
          this.dashboards.push(data);
        }
        if (data.dbtype == "screen") {
          this.screens.push(data);
        }
        if (data.dbtype == "background") {
          this.backgrounds.push(data);
        }
      }
    }
  },
  methods: {
    async reloadMenmonicsHandler() {
      try {
        const entityId = this.$store.state.data.customer;
        const result = await api.WellServices.getWells({ customer: entityId });
        let well_to_store_details = {};
        try {
          await db.well.clear();
        } catch (error) {}
        if(result && result.data){


        for (let i in result.data) {
          let well_obj = result.data[i];
          well_to_store_details[i] = { ...result.data[i] };
          for (let j in well_obj["wellbores"]) {
            well_obj = { ...result.data[i], ...result.data[i]["wellbores"][j] };
            // objectStore.put({ well_id: i, data: well_obj });
            try {
              await db.well.add({ well_id: i, data: well_obj });
            } catch (error) {}

            // delete well_obj.wellbores
            for (let k in well_obj["wellbores"][j]["logs"]) {
              delete well_to_store_details[i]["wellbores"][j]["logs"][k].tags;
              delete well_to_store_details[i]["wellbores"][j]["logs"][k]
                .displayTags;
              delete well_to_store_details[i]["wellbores"][j]["logs"][k].units;
              delete well_to_store_details[i]["wellbores"][j]["logs"][k]
                .description;
              delete well_to_store_details[i]["wellbores"][j]["logs"][k]
                .mnemonics;
            }
            delete well_obj.wellbores;
          }
        }
        this.getTags();
      }
      } catch (error) {
        console.log("error loadind well", error);
      }
    },
    toolTipHandler(ind) {
      this.activeToolTip = ind;
    },
    async getTags() { 
      console.log('__________gettttTAGSSSSS', this.$store.state.disp.displays)
      let display = this.$store.state.disp.selectedDisplay;
      console.log('__________gettttTAGSSSSS_display', display)
      let displayobj = this.$store.state.disp.displays;
      console.log('__________gettttTAGSSSSS_displayobj', displayobj)
      if (displayobj[display]) {
        let displayobj1 = await helperServices.getIndexDetails(
          displayobj[display].wellId
        );
        displayobj1 = displayobj1.data["logs"][displayobj[display].logType];

        this.mneTags = displayobj1.tags;
        this.units = displayobj1.units;

        this.logId = displayobj1.logId;
        this.state = displayobj1.wellboreState;
        this.description = displayobj1.description;
        this.displayTags = displayobj1.displayTags;
        const mnemonics = displayobj1.mnemonics;
        const mnk = Object.keys(displayobj1.mnemonics);
        this.tags = [];
        mnk.forEach((m) => {
          if (m != "MISCELLANEOUS") {
            const currentMn = Object.keys(mnemonics[m]);
            currentMn.forEach((n) => {
              this.tags.push(mnemonics[m][n].display_name);
            });
          }
        });
        // this.tags = this.displayTags;
        return [];
      } else {
        this.tags = [];
        return [];
      }
    },
    togglee() {
      this.checkedOrNot ? this.toggleDragHandler() : this.toggleDragHandler();
    },
    checkHovering(index) {
      if (index == this.hoverIndex) {
        return true;
      } else {
        return false;
      }
    },
    onHover(event, index) {
      this.hoverIndex = index;
      this.isHovering = true;

      this.hoverLeftSidebarX = event.clientY;
      this.hoverleftSiderbarY = event.clientX;
    },
    onHoverOut() {
      this.hoverIndex = -1;
      this.isHovering = false;
    },
    dasboardClicked(id) {
      this.$store.dispatch("disp/dashboardClicked", {
        savedDbId: id,
      });
    },
    screenClicked(id) {
      this.$store.dispatch("disp/screenClicked", {
        savedId: id,
      });
    },
    toggleModal(e, detail) {
      this.errcode = "";
      if (e) e.preventDefault();
      if (detail) {
        this.activeFor = detail.activeFor;
        if (detail.savedDbId) {
          this.savedId = detail.savedDbId;
          this.savedName = detail.savedDbName;
        } else {
          this.savedId = detail.savedId;
          this.savedName = detail.savedName;
        }
      } else {
        this.savedId = "";
        this.savedName = "";
      }
      document.getElementById("sidebar-modal").classList.toggle("hidden");
      // alert("model works")
    },
    async onDelete() {
      let response = await api.DashBoardServices.deleteSavedContent({
        id: this.savedId,
      });
      this.toggleModal();
      this.$store.dispatch("disp/updateCall", {});
      if (response.status == 200) {
        this.$toast.error("Deleted Successfully", {
          position: "top",
          duration: "2000",
        });
      } else {
        if (response.response) {
          this.$toast.error(response.response.message, {
            position: "top",
            duration: "2000",
          });
        }
      }
    },
    async onClear() {
      this.$store.dispatch("disp/clearSelectedDisplay", {
        savedDbId: this.savedId,
      });

      this.activeIndexDB = -1;
      this.toggleModal();
    },
    async onRename() {
      if (!this.savedName) {
        this.$toast.error("Please enter a valid name", {
          position: "top",
          duration: "2000",
        });
        this.errcode = "Please enter a valid name";
        return {};
      }

      if (this.savedName.length > 14) {
        this.$toast.error("Name must be less than 15 characters", {
          position: "top",
          duration: "2000",
        });
        this.errcode = "Name must be less than 15 characters";
        return {};
      }

      let nameExists = await api.DashBoardServices.getNameExists({
        name: this.savedName,
      });

      if (nameExists && nameExists.data) {
        this.errcode = "NAME ALREADY EXISTS";
        this.$toast.error("NAME ALREADY EXISTS", {
          position: "top",
          duration: "2000",
        });
        return {};
      }

      let response = await api.DashBoardServices.renameSavedContent({
        id: this.savedId,
        re_name: this.savedName,
      });

      //
      if (this.savedId && this.savedName) {
        this.$store.dispatch("disp/changeDisplayName", {
          savedId: this.savedId,
          savedName: this.savedName,
        });
      } else {
        this.$store.dispatch("disp/updateCall", {});
      }
      this.toggleModal();
      if (response.status == 200) {
        this.$toast.success("Renamed Successfully", {
          position: "top",
          duration: "2000",
        });
      } else {
        if (response.response) {
          this.$toast.error(response.response.message, {
            position: "top",
            duration: "2000",
          });
        }
      }
    },
    startDrag(ev, props) {
      const rect = ev.target.getBoundingClientRect();
      let offsetX = ev.clientX - rect.x;
      let offsetY = ev.clientY - rect.y;

      ev.dataTransfer.dropEffect = "move";
      ev.dataTransfer.effectAllowed = "move";

      let index = this.displayTags.indexOf(props.title);
      let tagName = this.mneTags[index];
      let units = this.units[this.displayTags.indexOf(props.title)];

      let elProps = {
        ...props,
        name: props.name,
        offsetY: offsetY,
        offsetX: offsetX,
        color: props.color,
        height: props.height,
        width: props.width,
        title: tagName,
        fullName: props.title,
        units: units,
        logId: this.logId,
        state: this.wellboreState,
        borderDisplay: props.borderDisplay,
      };
      let propSring = JSON.stringify(elProps);
      //
      ev.dataTransfer.setData("elProps", propSring);
    },
    saveDragedIndex(ev, i) {
      this.activeIndexDB = i;
    },
    saveSrnDragedIndex(ev, i) {
      this.activeIndexSrn = i;
    },
    saveBgDragedIndex(ev, i) {
      this.activeIndexBg = i;
    },
    startDragSaved(ev, props) {
      const rect = ev.target.getBoundingClientRect();
      let offsetX = ev.clientX - rect.x;
      let offsetY = ev.clientY - rect.y;

      ev.dataTransfer.dropEffect = "move";
      ev.dataTransfer.effectAllowed = "move";
      let elProps = {
        ...props,
        offsetY: offsetY,
        offsetX: offsetX,
        isSaved: true,
      };

      //
      let propSring = JSON.stringify(elProps);
      //

      ev.dataTransfer.setData("elProps", propSring);
    },
    toggleDragHandler() {
      let displayId = this.$store.state.disp.selectedDisplay;
      this.$store.dispatch("disp/toggleDragHandler", {
        displayId: displayId,
      });
      this.$store.dispatch("rect/toggleDragHandler", {
        value: !this.isDraggable,
        displayId,
      });
    },
    toggleResizeHandler() {
      let displayId = this.$store.state.disp.selectedDisplay;
      this.$store.dispatch("rect/toggleResizeHandler", {
        displayId: displayId,
      });
    },
    toggleCollapseShow: function (classes) {
      // this.collapseShow = classes;
      if (!this.$store.state.uis.leftSideBarOpen) {
        this.toggleSidebar = "0";
      }
      if (!this.$store.state.rect.leftSideDbBarOpen) {
        this.toggleSidebar = "0";
      }
    },
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
    },
    toggleServices: function (tabService) {
      this.openService = tabService;
    },
    toggleLeftSideBar: function () {
      if (!this.$store.state.uis.leftSideBarOpen) {
        this.toggleSidebar = "0";
      } else {
        // this.toggleCollapseShow('bg-white m-2 py-3 px-6')
      }
      this.$store.dispatch("rect/toggleLeftSideBar");
      // this.$store.dispatch("disp/toggleLeftSideBar");
    },
    toggleLeftSideDbBar: function () {
      if (!this.$store.state.rect.leftSideDbBarOpen) {
        this.toggleSidebar = "0";
      } else {
        // this.toggleCollapseShow('bg-white m-2 py-3 px-6')
      }
      this.$store.dispatch("rect/toggleLeftSideDbBar");
      this.$store.dispatch("disp/toggleLeftSideDbBar");
    },
    warning: function () {
      if (this.value > 50) {
        return {
          color: "#e74c3c",
          animation: "anim .3s ease-in 1 alternate",
          orientation: "vertical",
          direction: "rtl",
        };
      }
    },
    dark() {
      document.querySelector("nav").classList.add("dark-mode");
      document.querySelector("div").classList.add("dark-mode");
      document.querySelector("button").classList.add("dark-mode");
      document.querySelector("a").classList.add("text-red-600");
      this.darkMode = true;
      this.$emit("dark");
    },

    light() {
      document.querySelector("nav").classList.remove("dark-mode");
      document.querySelector("div").classList.remove("dark-mode");
      document.querySelector("button").classList.remove("dark-mode");
      document.querySelector("a").classList.remove("text-red-600");
      this.darkMode = false;
      this.$emit("light");
    },
    toggleDataGapAnalysis(e) {
      if (e.target.checked) {
        this.show_DataGaps_Popup = true;
      } else {
        this.show_DataGaps_Popup = false;
      }
    },
    close_Data_Gap_Popup() {
      this.$refs.dataGapref.checked = false;
      this.show_DataGaps_Popup = false;
    },
  },
  components: {
    DataGapsAndNulls,
  },
  watch: {
    savedList(newValue, oldValue) {
      this.savedData = newValue;
      this.dashboards = [];
      this.screens = [];
      this.backgrounds = [];
      if (this.savedData)
        for (let data of this.savedData.data) {
          if (data.dbtype == "dashboard") {
            this.dashboards.push(data);
          }
          if (data.dbtype == "screen") {
            this.screens.push(data);
          }
          if (data.dbtype == "background") {
            this.backgrounds.push(data);
          }
        }
    },
    sideBarForProp(newValue, old) {
      this.search = "";
      if (newValue == "Mnemonics") this.getTags();

      // this.activeIndexDB = -1;
    },
  },
};
</script>
<style scoped>
.close-sidebar-16rem {
  left: -16rem;
  transition: left 600ms ease-out;
}

.mySlider {
  transform: rotate(270deg);
}

/* .bg_gray {
  background-color: #141414;
} */

.z_index_20 {
  z-index: 99999;
  height: 100vh;
}

.mx_height_side {
  height: 100%;
}

.text-gray-40 {
  color: #ffffff;
}

.dropdown_hover {
  background: #29292f;
  margin: 10px;
  border-radius: 5px;
}

/* .dropdown_hover:hover {
  background-color: #414141 !important;
} */
.dropdown_hover:hover {
  /* background-color: #5aba81 !important; */
  background-color: var(--activeTextColor) !important;
  color: #ffffff !important;
}

.dropdown_hover:hover > .listtxt {
  color: #fff !important;
}

.dropdown_hover:hover > .listicon svg path[fill] {
  fill: #fff !important;
}

.menu_label {
  font: normal normal normal 12px Poppins;
  color: #e4e4e4;
}

.menu_label_option {
  font: normal normal normal 11px Poppins;
  text-align: left;
  color: #e4e4e460;
  padding-left: 1.5rem;
}

.menu_label_option:hover {
  color: #e4e4e4;
}

.mt_50 {
  margin-top: 30px;
}

/* .hamburger_icon {
  position: absolute;
  left: 7px;
  top: 56px;
  z-index: 60;
} */

.form-group {
  display: block;
  margin-bottom: 0;
  margin-top: 0;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  font-size: 10px;
}

/* .form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid white;

  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 15px;
  border-radius: 50%;
} */

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.form-group-checkbox {
  margin-top: 0;
  margin-bottom: 0;
}

::-webkit-scrollbar {
  width: 2px !important;
  display: block !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--nav1BgClr) !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--activeTextColor) !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #5aba81 !important; */
  background: var(--activeTextColor) !important;
}

.search {
  padding: 10px;
  height: 10px;
  /* position: absolute; */
  left: 5px;
  width: 90%;
  margin: 0 auto;
  display: flex;
}

.left_bg {
  background: var(--sidebarbg);
  /* #cdcdcddb; */
  width: 9%;
  color: #000000 !important;
}

.drop_btn_light {
  background: #bdbdbd63;
  /* #ffffff; */
  color: #000000 !important;
  font-size: 10px;
  padding: 2px 10px;
  font-weight: 100;
  letter-spacing: 0.5px;
  margin: 0;
}

.dark-mode {
  background: #1b1a1f;
  color: white !important;
  transition-delay: 150ms;
  width: 9%;
}

/* .left_bg{
  background: white;
   color: black !important;
} */
.drop_btn {
  margin: 0;
  font-size: 0.55rem;
  padding: 2px 10px;
  font-weight: 100;
  letter-spacing: 0.5px;
  /* margin: 0;
    font-size: 10px;
    padding: 2px 10px;
    font-weight: 100;
    width: 100%;
    position: absolute; */
}

.left_mnemo {
  /* margin-top: 2rem; */
  /* border-bottom: 1px #0e1016 solid; */
  scrollbar-color: yellow blue;
  scrollbar-color: #87ceeb #ff5621;
  scrollbar-color: auto;

  /* -webkit-box-shadow: 0px 0px 0px #0e1016;
    -moz-box-shadow: 0px 0px 0px #0e1016;
    box-shadow: 0px 0px 10px #0e1016; */
}

.left_lists_ {
  height: 66vh;
  overflow-y: scroll;
  overflow-x: hidden;
  /* scrollbar-width: thin; */
}

/* .bg_green {
} */
.general_checks {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}

#dragPosition {
  border: 1px solid gray;
}

#resizePosition {
  border: 1px solid gray;
}

input {
  outline: 3 !important;
  -webkit-appearance: none;
}

input[type="checkbox"]:focus {
  outline: 0;
}

.alert_message {
  font-size: 6px;
  text-align: center;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  height: 100%;
}

.label_heading {
  font-weight: 700;
  color: unset;
  cursor: default;
  /* color: #ffffff !important; */
}

/* .label_heading:hover {
  color: #5aba81 !important;
} */
.menu_btns {
  position: absolute;
  left: 32px;
  top: 50px;
  z-index: 60;
}

.left_close_btn {
  padding: 0 1px;
  display: flex;
  height: 100%;
  align-self: center;
  width: 9px;
}

.bg_modal_btns_sidebar {
  width: 25%;
  font-size: 11px;
  padding: 3px;
  margin: 1px;
  color: white;
}

.btns_modal_p {
  display: flex;
  justify-content: space-between;
}

.bg_clear {
  background-color: orange;
}

.modal_width_sidebar {
  width: 32%;
}

.hoveringN {
  display: block;
  /* position: relative; */
  z-index: 99;
}

.transitionNs {
  /* position: relative;
  top: 1.5rem; */
  color: white;
  background: #000000d4;
  /* height: 25px; */
  width: 100%;
  font-size: 10px;
  z-index: 999;
  position: absolute;
  /* bottom: 25px; */
  /* top: 120px; */
}

.tooltipN {
  display: block;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

/* range slider */

.qs {
  background-color: #02bdda;
  border-radius: 16px;
  color: #e3fbff;
  cursor: default;
  display: inline-block;
  /* font-family: "Helvetica", sans-serif; */
  font-size: 18px;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}

.popover {
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 5px;
  bottom: 42px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  color: #fff;
  display: none;
  font-size: 12px;
  /* font-family: "Helvetica", sans-serif; */
  left: -95px;
  padding: 7px 10px;
  position: absolute;
  width: 200px;
  z-index: 4;
}

.popover:before {
  border-top: 7px solid rgba(0, 0, 0, 0.85);
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  bottom: -7px;
  content: "";
  display: block;
  left: 50%;
  margin-left: -7px;
  position: absolute;
}

.popover:hover {
  display: block;
  -webkit-animation: fade-in 0.3s linear 1, move-up 0.3s linear 1;
  -moz-animation: fade-in 0.3s linear 1, move-up 0.3s linear 1;
  -ms-animation: fade-in 0.3s linear 1, move-up 0.3s linear 1;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-ms-keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes move-up {
  from {
    bottom: 30px;
  }

  to {
    bottom: 42px;
  }
}

@-moz-keyframes move-up {
  from {
    bottom: 30px;
  }

  to {
    bottom: 42px;
  }
}

@-ms-keyframes move-up {
  from {
    bottom: 30px;
  }

  to {
    bottom: 42px;
  }
}

[tooltip] {
  position: relative;
  /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none;
  /* opinion 2 */
  font-size: 0.9em;
  /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}

[tooltip]::before {
  content: "";
  border: 5px solid transparent;
  /* opinion 4 */
  z-index: 999999;
  /* absurdity 1 */
}

[tooltip]::after {
  content: attr(tooltip);
  /* magic! */

  /* most of the rest of this is opinion */
  /* font-family: Helvetica, sans-serif; */
  text-align: center;

  /*
    Let the content set the size of the tooltips
    but this will also keep them from being obnoxious
    */
  min-width: 100%;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: 0.3ch;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: #000;
  color: #fff;
  z-index: 1000;
  /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip=""]::before,
[tooltip=""]::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #000000;
}

[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}

[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -0.5em);
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #000000;
}

[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}

[tooltip][flow^="down"]:nth-child(1):after {
  top: calc(-150% + 5px) !important;
}

[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, 0.5em);
}

/* FLOW: LEFT */
[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #000000;
  left: calc(0em - 5px);
  transform: translate(-0.5em, -50%);
}

[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-0.5em, -50%);
}

/* FLOW: RIGHT */
[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #000000;
  right: calc(0em - 5px);
  transform: translate(0.5em, -50%);
}

[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(0.5em, -50%);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}

.left_z_index {
  /* z-index: 999999; */
  z-index: 9999;
}

/* left side bar tooltip with first child bottom */

.selectedBtn {
  padding: 2px 0px;
  position: relative;
}

.selectedBtn_tooltip {
  width: 100%;
}

.tooltip_opens {
  display: none;
  color: black;
}

.selectedBtn:first-child .selectedBtn_tooltip:hover + .tooltip_opens::before {
  content: "\1F781";
  font-size: 14px;
  position: absolute;
  right: 49px;
  top: -9px;
  text-align: center;
  color: #040405;
}

.selectedBtn_tooltip:hover + .tooltip_opens::before {
  content: "\1F783";
  font-size: 14px;
  position: absolute;
  right: 49px;
  top: 5px;
  text-align: center;
  color: #040405;
}

.selectedBtn_tooltip:hover + .tooltip_opens {
  display: inline;
  position: absolute;
  /* top: -40%; */
  top: calc(-116% + 15px);
  left: 0;
  z-index: 9999;
  background: #000;
  color: #fff;
  width: 100%;
  text-align: center;
  font-size: 10px;
  animation: tooltips-horz 300ms ease-out forwards;
  /* transform: translate(.5em, -50%); */
}

.selectedBtn:first-child .selectedBtn_tooltip:hover + .tooltip_opens {
  display: inline;
  position: absolute;
  /* top: 155%; */
  left: 0;
  top: calc(100% + 15px);
  z-index: 9999;
  background: #000;
  color: #fff;
  width: 100%;
  text-align: center;
  /* font-size: 10px; */
  animation: tooltips-horz 300ms ease-out forwards;
}

.parent {
  background: unset;
  width: 92%;
  height: 100%;
  margin: 0 auto;
  /* position:relative */
}

/*  */

.tooltip__tip {
  position: relative;
}

.tooltip__tip::after {
  background-color: black;

  border-radius: 10px;
  display: none;
  padding: 10px 20px;
  position: absolute;
  text-align: center;
  z-index: 999;
}

.tooltip__tip::before {
  background-color: #e5e5e5;
  content: "";
  display: none;
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: 999;
}

.tooltip__tip:hover::after {
  display: none;
}

.tooltip__tip:hover::before {
  display: none;
}

.tooltip__tip.top::after {
  content: attr(data-tip);
  top: 2px;
  left: 50%;
  width: 100%;
  font-size: 12px;
  padding: 0px;
  transform: translate(-50%, calc(-100% - 10px));
  background: #e5e5e5;
  color: #1c1d1f;
  font-weight: 600;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
  white-space: break-spaces;
}

.tooltip__tip.top::before {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.parent {
  background: unset;
  width: 92%;
  height: 100%;
  margin: 0 auto;
  /* position: relative; */
}

.selectedBtn {
  position: relative;
}

.selectedBtn_tooltip {
  width: 100%;
  /* width: 100%;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap; */
}

._popper_styles {
  border: 0 solid transparent !important;
  margin: 0 !important;
  width: 100%;
}

/* styles for sidebar start */
.asidebar {
  border-radius: 0px;
  height: calc(100vh - 90px);
  padding: 5px;
  padding-top: 8px;
}

.asidebarDiv {
  /* margin: 10px 5px; */
  margin-bottom: 0px;
  width: 12% !important;
}

.sideBarBG {
  background: var(--nav1BgClrsame) !important;
  color: white !important;
  border-right: 1px solid var(--navBar2Bgsame);
}

.sideBarHdng {
  margin: 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--headingColor);
  padding: 0px;
}

.bgDark {
  background: var(--searchBarBg);
}

.bgLight {
  background: var(--searchBarBg);
}

.asideSearch {
  padding: 0px 10px;
  margin-top: 5px;
  margin-bottom: 4px;
  height: 30px;
  border-radius: 25px;
}

.asideSearchIp {
  height: 100%;
  width: 100%;
  background: transparent;
  border: none;
  box-shadow: none;
  color: var(--searchColor);
  font-size: 13px;
  padding-right: 0px;
  padding-left: 8px;
}

.asideSearchIp::placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--headingColor) !important;
}

.sideBarListDiv {
  margin: 0px;
  width: 100%;
}

.listicon {
  width: 12px;
}

.listtxt {
  padding-left: 10px;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  /* color: var(--sidebarListColor); */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.sideBarListBtn {
  height: 30px;
  background: var(--root_nav);
  margin-bottom: 4px;
  padding: 0px 10px;
  border-radius: 0px;
  width: 100%;
}

.checkbox-label {
  background-color: var(--headingColor);
  width: 30px;
  height: 13px;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
}

.checkbox-label .ball {
  background-color: var(--sidebarbg);
  width: 10.5px;
  height: 10.5px;
  position: absolute;
  left: 3px;
  top: 1px;
  border-radius: 50%;
  /* transition: transform 0.2s linear; */
}

.checkbox:checked + .checkbox-label .ball {
  transform: translateX(14px);
}

.checkbox:checked + .checkbox-label {
  background-color: var(--activeTextColor);
}

.custom_pop {
  background: yellow;
  color: black;

  z-index: 999999;
}

.custom_pop:hover {
  display: block;
}

.popUpp {
  display: none;
  background: #e5e5e5;
  color: black;
  padding: 5px;
  border-radius: 0;
  font-size: 11px;
}

.tooltip__tip:hover + .popUpp {
  position: fixed;
  z-index: 9999999999;
  display: block !important;
  position: fixed;
  z-index: 2;
  /* box-shadow: 0px 0px 2px 1px var(--sideBarPopUpColor); */
  font-weight: 500;
  color: var(--sideBarPopUpColor) !important;
  background: var(--sideBarPopUpBg) !important;

  text-align: center;
}

.vr_elip {
  font-size: 2px;
  width: 10px;
  height: 10px;
  color: var(--nav1HdngClr);
}

.vr_elip path {
  fill: var(--nav1HdngClr);
}

.tooltip__tip:hover .vr_elip path {
  fill: #fff;
}

.newCheck {
  opacity: 0;
  width: 50px;
  height: 50px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 14px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--toggleSliderBg);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  /* top: 1px; */
  bottom: 1px;
  background-color: var(--sidebarbg);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--activeTextColor);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--activeTextColor);
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.asideSearchIcon > path {
  fill: var(--activeTextColor);
}

.sideBarHdng {
  font-size: calc(0.3vw + 0.3vh + 0.35vmin) !important;
  color: var(--textColor) !important;
  justify-content: center;
  letter-spacing: 0.15em;
}

.listtxt {
  font-style: normal;
  font-size: calc(0.3vw + 0.3vh + 0.35vmin) !important;
}

.asideSearchIp {
  font-size: calc(0.35vw + 0.4vh + 0.45vmin);
}

.asideSearchIp::placeholder {
  font-size: calc(0.35vw + 0.4vh + 0.45vmin);
}

/* styles for sidebar end */
.customtooltip {
  left: 12%;
  top: 37px;
  font-size: 8px;
}

.theme_text {
  color: white;
}
</style>
